<template>
  <b-card-code>
    <!-- <p>{{organization}}organisation</p> -->
    <b-button
      @click="addGroup()"
      type="button"
      variant="primary"
      class="my-1"
      size="sm"
    >
      Add New
    </b-button>
    <b-card-code class="bg-light-secondary mb-1" title="Search">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="row" style="width: 100%; padding: 5px">
          <!-- <b-form-select
            class="ml-1"
            v-if="is_staff"
            @change="searchFn"
            v-model="organization"
            :options="organizations"
          >
          </b-form-select> -->
          <!-- <v-select
            v-if="is_staff"
            v-model="organization"
            label="text"
            placeholder="--Select Organization--"
            :options="organizations"
            @input="searchFn"
            autocomplete
            style="width: 20%"
            :reduce="organization => organization.value"
          /> -->

          <b-form-input
            class="ml-1"
            id="name"
            v-model="name_filter"
            style="width: 70%"
            name="name"
            placeholder="Search with name"
          />
          <b-button
            style="width: 12%"
            class="ml-1"
            variant="primary"
            @click="searchFn"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>
              <feather-icon icon="SearchIcon" class="mr-50" />Search</span
            >
          </b-button>
        </div>
      </b-form>
    </b-card-code>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-table
        responsive
        id="atTable"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        v-if="items.length"
        hover
        style="min-height: 200px"
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(group_name)="data">
          <!-- <router-link :to="'/user/teams/' + data.item.team_id + '/users'">{{
          data.item.team_name
        }}</router-link> -->

          <div class="d-flex align-items-center">
            <!-- <router-link :to="'/user/teams/' + data.item.team_id + '/users'"> -->
            <b-avatar
              variant="primary"
              size="sm"
              :text="data.item.team_name.charAt(0)"
            />
            <span style="margin-left: 5px">{{ data.item.team_name }}</span>
            <!-- </router-link> -->
          </div>
        </template>
        <template #cell(actions)="data">
          <!-- <button @click="editAssetType(data.item.asset_type_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteAssetType(data.item.asset_type_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button>
         -->
          <b-dropdown
            size="sm"
            class="ml-1"
            variant="outline-primary"
            text="Actions"
          >
            <b-dropdown-item @click="manageGroup(data.item.team_id)"
              >Manage Users</b-dropdown-item
            >
            <b-dropdown-item @click="editGroup(data.item.team_id)"
              >Edit</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item
              @click="gotoDelete(data.item.team_id, data.item.team_name)"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
    </div>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="atTable"
    />

    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ team_name }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteGroup()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BAvatar,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    vSelect,
    BAvatar,
  },
  data() {
    return {
      // Call orgaization API and return in this format
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        // { key: "team_id", label: "#" },
        { key: "index", label: "#" },
        { key: "group_name", label: "Name" },
        { key: "o_profile.org_name", label: "Organization" },
        { key: "team_description", label: "Description" },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      organizations: [],
      name_filter: "",
      loading: false,
      team_name: "",
      team_id: "",
      openDeleteModal: false,
      isloading: false,
    };
  },
  created: function () {
    // this.load();
    // this.loadOrg();
    this.searchFn();
  },
  methods: {
    // loadOrg: function () {
    //   if (this.is_staff) {
    //     this.loading = true;

    //     const o_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "organization/organization/get-all-org",
    //     };
    //     var self = this;
    //     this.$http(o_options).then((res) => {

    //       res.data.map(function (value, key) {
    //         let org = {
    //           value: res.data[key].org_id,
    //           text: res.data[key].org_name,
    //         };

    //         self.organizations.push(org);
    //       });
    //       this.loading = false;
    //     });
    //   } else {
    //     this.organization = this.$store.state.app.user.profile.organization;
    //     this.searchFn();
    //   }
    // },

    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "user/teams",
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data;
        this.rows = res.data.length;
      });
    },
    searchFn: function (reset = true) {
      this.isloading = true;
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url = process.env.VUE_APP_BASEURL + "user/teams?query=true";

      if (this.organization != null) {
        this.filter.push({ organization: this.organization });
        url = url + "&org_id=" + this.organization;
      }
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name: this.name_filter });
        url = url + "&name=" + this.name_filter;
      }

      console.log(this.filter);
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data;
        this.rows = res.data.length;
        this.loading = false;
        this.isloading = false;
      });
    },
    addGroup() {
      this.$router.push({ name: "Add Team" });
    },
    editGroup(id, name) {
      this.$router.push({ name: "Edit Team", params: { id: id } });
    },
    manageGroup(id, name) {
      this.$router.push({ name: "Manage Team", params: { id: id } });
    },
    gotoDelete(team_id, team_name) {
      this.openDeleteModal = true;
      this.team_id = team_id;
      this.team_name = team_name;
    },
    deleteGroup() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "user/teams/" + this.team_id + "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
      });
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
